.itemCard {
  width: 100%;
  background-color: #2b2c31;
  border: none;
  border-radius: 0px;
  transition: all 300ms ease-in-out;
  &:hover {
    transform: (scale(1.01));
    transition: all 300ms ease-in-out;
  }
  .item-card-header {
    border: none;
    border-radius: 0px;
    background: linear-gradient(
      90deg,
      rgba(168, 238, 113, 1) 0%,
      rgba(253, 255, 151, 1) 100%
    );
    height: 1.5rem;
    text-align: center;
    font-weight: 400;
    font-size: 0.85rem;
    letter-spacing: 0.01rem;
    color: #000000;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .item-card-test-class {
    display: flex;
    justify-content: flex-end;
    margin-top: 0.7rem;
    .item-card-test-class-title {
      color: white;
      background-color: rgba(0, 0, 0, 0.3);
      width: 6.8rem;
      font-weight: 400;
      font-size: 0.75rem;
      padding: 4px 9px;
    }
  }
  .item-card-body {
    padding: 0;
    display: flex;
    flex-direction: column;
    .item-card-title-container {
      width: 100%;
      padding-left: 1.5%;
      margin-top: 0.5rem;
      .item-card-title {
        text-align: start;
        font-weight: 700;
        font-size: 1.8rem;
        color: #ffffff;
      }
    }
    .item-card-inner-container {
      padding: 0 4%;
      .item-card-disc-container {
        margin-top: 4%;
        padding: 2% 0;
        .item-card-start-date-container {
          display: flex;
          margin: 2% 0%;
          .item-card-img {
            align-self: center;
            width: 15px;
            height: 15px;
            margin: 0 2%;
          }
          .item-card-start-date {
            font-weight: 400;
            font-size: 1rem;
            letter-spacing: 0.01rem;
            color: #bdbec0;
            .item-card-start-date-strong {
              color: rgba(253, 255, 151, 1);
              margin-left: 10px;
            }
          }
        }
        .item-card-duration-month-container {
          width: 100%;
          display: flex;
          margin: 5% 0%;
          color: #fff;
          .item-card-img-calendar {
            align-self: center;
            width: 15px;
            height: 15px;
            margin: 0 2%;
          }
        }
      }
      .item-card-becademy-container {
        border-top: 1px solid rgb(74, 74, 74);
        margin-top: 2%;
        padding: 4% 2%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        .item-card-quota-available {
          font-size: 0.9rem;
          text-align: center;
          color: rgba(168, 238, 113, 1);
        }
        .item-card-quota-none {
          color: rgb(254, 106, 106);
          font-size: 0.9rem;
          text-align: center;
        }
        .item-card-becademy {
          margin-top: 2%;
          font-size: 0.9rem;
          text-align: center;
          color: #bdbec0;
        }
      }
      .item-card-price-container {
        margin-top: 3.5%;
        padding: 0 2%;
        .item-card-dues-price {
          display: flex;
          justify-content: flex-start;
          flex-direction: column;
          .item-card-dues {
            text-align: start;
            font-weight: 700;
            font-size: 0.8rem;
            letter-spacing: 0.01em;
            color: #ffffff;
          }
          .item-card-price-amount {
            color: #ffffff;
            font-size: 2.5rem;
            text-align: start;
          }
        }
      }
    }
  }
  .item-card-footer {
    margin-top: 2%;
    border: none;
    border-radius: 0px;
    height: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    .item-card-button {
      color: #c8faa0;
      background: #383b42;
      border: none;
      padding: 0.55rem 4.5rem;
      &:hover {
        background: linear-gradient(
          90deg,
          rgba(168, 238, 113, 1) 0%,
          rgba(253, 255, 151, 1) 100%
        );
        color: black;
        transform: scale(1.1);
      }
    }
  }
}
