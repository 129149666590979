.debts-home-main-container {
  width: 100%;
  padding: 1%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #212529;
  .debts-home-title-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2%;
    .debts-home-title {
      color: #fdff97;
      text-align: center;
      font-family: "Inria Sans", sans-serif;
      padding-bottom: 1%;
      font-size: 2.05em;
      letter-spacing: 0.03em;
      text-shadow: -2px 3px 3px rgba(255, 255, 255, 0.261);
      margin-top: 1%;
    }
  }
  .debts-home-table-container {
    padding: 1%;
    width: 100%;
    border: 1px solid #fdff97;
    border-radius: 15px;
    .debts-home-table {
      --bs-table-border-color: #fdff97;
      --bs-table-hover-bg: rgba(226, 227, 231, 0.52);
      --bs-table-hover-color: black;
      .debts-home-column-title {
        text-align: center;
        font-size: 1.15em;
        font-family: "Inria Sans";
        padding: 1%;
      }
      .debts-home-column-data {
        padding: 1% 0%;
        text-align: center;
        font-size: 1em;
        font-family: "Inria Sans";
        padding: 1%;
      }
    }
  }
}
