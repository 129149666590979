.sellers-form-main-container {
    padding: 2%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .sellers-form-grid-container {
        border-top: 2%;

        .sellers-form {
            border: 1px solid #fdff97;
            border-radius: 15px;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            padding: 2% 0%;


            .sellers-form-grid-row {
                width: 100%;
                margin: 1% 0%;

                .sellers-form-grid-item {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;

                    .sellers-form-input-label {
                        font-size: 1.35rem;
                        letter-spacing: 0.2rem;
                        font-family: "Inria Sans", sans-serif;
                        color: #fdff97;
                        width: 50%;
                    }

                    .sellers-form-input {
                        padding: 2%;
                        font-size: 1.1rem;
                        font-family: "Inria Sans", sans-serif;
                    }

                    .sellers-form-input-error {
                        padding: 0% 0.85%;
                        margin-top: 0.8%;
                        text-align: start;
                        font-family: "Inria Sans", sans-serif;
                        color: red;
                    }

                    .psw-button {
                        border: none;
                        border-radius: 5px;
                        width: 15%;
                        padding: 1.5% 1.5%;
                    }
                }
            }

            .sellers-form-sumbit-btn-container {
                margin: 3% 0%;
                width: 50%;
                display: flex;
                justify-content: space-between;

                .sellers-form-submit-btn {
                    font-family: "Inria Sans", sans-serif;
                    font-size: 1.12em;
                    border: 1px solid #b0f2c2;
                    border-radius: 15px;
                    background: transparent;
                    color: #b0f2c2;
                    padding: 2%;
                    margin: 1% auto;
                    // width: 25%;
                    max-width: 18vw;
                    min-width: 15vw;

                    &:hover {
                        transform: scale(1.05);
                        background-color: #b0f2c2;
                        color: #212529;
                    }

                    &:active {
                        transform: scale(0.95);
                    }
                }

                .sellers-form-back-btn {
                    font-family: "Inria Sans", sans-serif;
                    font-size: 1.12em;
                    border: 1px solid#d2bead;
                    border-radius: 15px;
                    background: transparent;
                    color: #d2bead;
                    padding: 2%;
                    margin: 1% auto;
                    // width: 25%;
                    max-width: 18vw;
                    min-width: 15vw;

                    &:hover {
                        transform: scale(1.05);
                        background-color: #d2bead;
                        color: #212529;
                    }

                    &:active {
                        transform: scale(0.95);
                    }
                }
            }
        }
    }
}