.login-form-main-container {
  background-color: #212529;
  .login-form-grid-container {
    .login-form {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .login-form-grid-row {
        width: 100%;
        margin: 1% 0%;
        .login-form-item {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          .input-label {
            font-size: 1.35rem;
            letter-spacing: 0.2rem;
            font-family: "Inria Sans", sans-serif;
            color: #fdff97;
          }
          .login-form-input {
            padding: 2%;
            font-size: 1.1rem;
            font-family: "Inria Sans", sans-serif;
          }
          .login-form-input-error {
            padding: 0% 0.85%;
            margin-top: 0.8%;
            text-align: start;
            font-family: "Inria Sans", sans-serif;
            color: red;
          }
          .psw-button {
            border: none;
            border-radius: 5px;
            width: 15%;
            padding: 1.5% 1.5%;
            background-color: #212529;
          }
        }
      }

      .submit-button-container {
        margin: 3% 0%;
        width: 60%;
        padding: 0% 15%;
        display: flex;
        justify-content: center;
        flex-direction: row;
        @media screen and (max-width: 768px) {
          flex-direction: column-reverse;
        }
        .submit-button {
          font-family: "Inria Sans", sans-serif;
          font-size: 1.12em;
          border: 1px solid #b0f2c2;
          border-radius: 15px;
          background: transparent;
          color: #b0f2c2;
          padding: 2%;
          min-width: 10vw;
          max-width: 25vw;
          margin: 0% 5%;
          @media screen and (max-width: 768px) {
            margin: 8% 0%;
          }
          &:hover {
            transform: scale(1.05);
            background-color: #b0f2c2;
            color: #212529;
          }
          &:active {
            transform: scale(0.95);
          }
        }
        .back-button {
          font-family: "Inria Sans", sans-serif;
          font-size: 1.12em;
          border: 1px solid#d2bead;
          border-radius: 15px;
          background: transparent;
          color: #d2bead;
          padding: 2%;
          min-width: 10vw;
          max-width: 25vw;
          margin: 0% 5%;
          @media screen and (max-width: 768px) {
            margin: 8% 0%;
          }
          &:hover {
            transform: scale(1.05);
            background-color: #d2bead;
            color: #212529;
          }
          &:active {
            transform: scale(0.95);
          }
        }
      }
    }
  }
}
