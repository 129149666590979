.buttonWsp {
  width: 50px;
  height: 50px;
  line-height: 50px;
  background-color: #ffffff;
  color: #0df053;
  border-radius: 50px;
  text-align: center;
  font-size: 33.5px;
  z-index: 3;
  &:hover {
    text-decoration: none;
    background-color: #0df053;
    color: #ffffff;
  }
}
