.slider-container {
  .slick-slider {
    width: 100%;
    overflow: hidden;
    display: grid;
    place-items: center;
    .slick-list {
      position: relative;
      display: grid;
      place-items: center;
      width: 100%;
      margin: 0 -10px;
      .slick-track {
        padding: 3.2rem 0;
        width: 100vw;
        display: flex;
        .slick-slide {
          margin: 0 10px;
          height: 30rem;
          width: 25rem;
          > div {
            width: auto;
            > .itemCard {
              max-width: 25rem;
            }
          }
        }
      }
    }
  }
}

.slick-prev,
.slick-next {
  z-index: 1000 !important;
}
.slick-prev {
  left: 1rem !important;
}
.slick-next {
  right: 1rem !important;
}

.center {
  .slick-center {
    > div {
      .itemCard {
        transform: scale(1.035);
        z-index: 1000;
        transition: all 400ms ease-in-out;
        pointer-events: all;
        &:hover {
          transform: (scale(1.075));
          transition: all 400ms ease-in-out;
        }
      }
    }
  }
}
