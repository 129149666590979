.profile-detail-main-container {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 3%;
  background-color: #212529;
  .profile-detail-title-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: left;
    margin-bottom: 1%;
    .profile-detail-title {
      color: #fdff97;
      text-align: center;
      font-family: "Inria Sans", sans-serif;
      padding-bottom: 1%;
      font-size: 2.05em;
      letter-spacing: 0.03em;
      text-shadow: -2px 3px 3px rgba(255, 255, 255, 0.261);
    }
  }
  .profile-detail-grid-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .profile-detail-grid-row {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-around;
      .profile-detail-grid-item {
        margin: 2% 0%;
        .profile-detail-data-container {
          width: 100%;
          background-color: #fdff97;
          padding: 2% 3%;
          border-radius: 15px;
          .profile-detail-data-title {
            background-color: transparent;
            text-align: center;
            font-family: "Inria Sans", sans-serif;
            font-weight: bold;
            padding-bottom: 1%;
            font-size: 1.75em;
            letter-spacing: 0.03em;
            text-shadow: -2px 1px 1px #9748ff8e;
          }
          .profile-detail-data {
            background-color: transparent;
            color: #191919;
            text-align: center;
            font-family: "Inria Sans", sans-serif;
            font-size: 1.25em;
            letter-spacing: 0.03em;
            border-top: 1px solid black;
            padding-top: 1%;
            .profile-detail-schedules-list {
              background-color: #212529;
              .profile-detail-available-schedules {
                background-color: transparent;
              }
            }
          }
        }
      }
    }
  }
  .seller-profile-table-title{
      width: 100%;
      color: #fdff97;
      text-align: left;
      font-family: "Inria Sans", sans-serif;
      padding-bottom: 1%;
      font-size: 2.05em;
      letter-spacing: 0.03em;
      text-shadow: -2px 3px 3px rgba(255, 255, 255, 0.261);
      margin-top: 1%;
      text-decoration: underline;
  }
  .seller-profile-table-container {
    padding: 1%;
    width: 100%;
    border: 1px solid #fdff97;
    border-radius: 15px;

    .seller-profile-table {
      --bs-table-border-color: #fdff97;
      --bs-table-hover-bg: rgba(226, 227, 231, 0.52);
      --bs-table-hover-color: black;

      .seller-profile-column-title {
        text-align: center;
        font-size: 1.15em;
        font-family: "Inria Sans";
        padding: 1%;
      }

      .seller-profile-column-data {
        padding: 1% 0%;
        text-align: center;
        font-size: 1em;
        font-family: "Inria Sans";
        padding: 1%;
      }
    }
  }
}
