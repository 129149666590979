.home-container {
  font-family: "Inria Sans", sans-serif;
  width: 100%;
  flex-direction: column;
  align-items: center;
  background-color: #212529;
  .home-landing-free-cut-link {
    text-decoration: none;
    .home-landing-free-cut-btn {
      background-color: #212529;
      padding: 0.5%;
      background: linear-gradient(90deg, #e29500 0%, #c3fc97 100%);
      display: flex;
      justify-content: center;
      text-align: center;
      .free-cut-title {
        display: flex;
        justify-content: center;
        font-size: 1.55rem;
        color: #2125299a;
        font-weight: 600;
        font-family: "Alumni Sans", sans-serif;
        .free-cut-title-strong {
          color: black;
          margin-left: 0.3rem;
          font-weight: bold;
          letter-spacing: 0.01rem;
        }
      }
    }
  }
  span {
    color: #ffd66b;
  }
  .titulo {
    color: white;
    padding-top: 43px;
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;
    text-align: center;
  }
  .home-landing-description-container {
    width: 100%;
    display: flex;
    justify-content: center;
    .descripcion {
      font-family: "Inria Sans", sans-serif;
      line-height: 29px;
      max-width: 375px;
      text-align: center;
      color: white;
      @media screen and (max-width: 768px) {
        max-width: 251px;
      }
    }
  }
  .home-landing-button-container {
    width: 100%;
    display: flex;
    justify-content: center;
    .all-products-button {
      margin-top: 1%;
      border: 1px solid #ffd66b;
      padding: 1.2rem;
      border-radius: 8px;
      color: white;
      text-decoration: none;
      font-size: 1.12rem;
      background-color: #353535;
      &:hover {
        background: linear-gradient(
          90deg,
          rgba(255, 214, 107) 0%,
          rgba(253, 255, 151, 1) 100%
        );
        color: black;
        transform: scale(1.1);
      }
    }
  }
  .home-landing-carrusel-container {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .home-langing-tools-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .home-landing-address-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .address-title-container {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      padding: 1.3rem 5rem;
      @media screen and (max-width: 768px) {
        padding: 2rem 1rem;
      }
      .address-title {
        color: white;
        font-family: "Inria Sans";
        font-style: normal;
        font-weight: 700;
        font-size: 2.5rem;
        line-height: 42px;
      }
    }
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .home-landing-map-container {
    margin: 5% 0%;
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
