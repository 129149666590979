.course-detail-main-container {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 3%;
  background-color: #212529;
  .course-detail-title-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 1%;
    .course-detail-title {
      color: #fdff97;
      text-align: center;
      font-family: "Inria Sans", sans-serif;
      padding-bottom: 1%;
      font-size: 2.05em;
      letter-spacing: 0.03em;
      text-shadow: -2px 3px 3px rgba(255, 255, 255, 0.261);
    }
  }
  .course-detail-grid-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .course-detail-grid-row {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-around;
      .course-detail-grid-item {
        margin: 2% 0%;
        .course-detail-data-container {
          width: 100%;
          background-color: #fdff97;
          padding: 2% 3%;
          border-radius: 15px;
          .course-detail-data-title {
            background-color: transparent;
            text-align: center;
            font-family: "Inria Sans", sans-serif;
            font-weight: bold;
            padding-bottom: 1%;
            font-size: 1.75em;
            letter-spacing: 0.03em;
            text-shadow: -2px 1px 1px #9748ff8e;
          }
          .course-detail-data {
            background-color: transparent;
            color: #191919;
            text-align: center;
            font-family: "Inria Sans", sans-serif;
            font-size: 1.25em;
            letter-spacing: 0.03em;
            border-top: 1px solid black;
            padding-top: 1%;
            .course-detail-schedules-list {
              background-color: #fdff97;
              .course-detail-available-schedules {
                background-color: transparent;
              }
            }
          }
        }
      }
    }
    .course-detail-buttons-container {
      width: 70%;
      display: flex;
      padding: 2% 0%;
      justify-content: space-around;
      .course-detail-buton {
        font-family: "Inria Sans", sans-serif;
        font-size: 1.12em;
        border: 1px solid#d2bead;
        border-radius: 15px;
        background: transparent;
        color: #fdff97;
        padding: 2%;
        min-width: 15vw;
        max-width: 18vw;
        &:hover {
          transform: scale(1.05);
          background-color: #fdff97;
          color: #212529;
        }
        &:active {
          transform: scale(0.95);
        }
      }
    }
  }
}
