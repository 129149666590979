.divider {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2% 0%;
  padding: 1% 0;
  width: 100%;
  @media screen and (max-width: 768px) {
    margin: 5% 0%;
  }
  > img {
    width: 70%;
    color: white;
    @media screen and (max-width: 768px) {
      width: 90%;
      margin: 2% 0%;
    }
  }
}
