.spd-main-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 2%;
  background-color: #212529;
  color: #ffffff;
  font-family: "Inria Sans";
  .spd-title-container {
    text-align: center;
    margin-bottom: 1%;
    .spd-title {
      font-size: 2.05rem;
      color: #fdff97;
      letter-spacing: 0.03em;
      text-shadow: -2px 3px 3px rgba(255, 255, 255, 0.261);
    }
  }
  .spd-section {
    margin-bottom: 3%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    .spd-progress-container {
      padding: 1% 0;
      .spd-progress {
        display: flex;
        justify-content: space-around;
        flex-direction: row;
        @media screen and (max-width: 768px) {
          flex-direction: column;
        }
        .spd-progress-bar-container {
          width: 100%;
          padding: 0 1%;
          display: flex;
          justify-content: center;
          align-items: center;
          @media screen and (max-width: 768px) {
            margin-top: 5%;
          }
          .progress-bar {
            width: 100%;
            max-width: 45vw;
            @media screen and (max-width: 768px) {
              max-width: 85vw;
            }
            height: 2rem;
            background-color: rgb(45, 49, 53);
            color: white;
          }
        }
      }
    }
    .spd-certificates-title {
      @media screen and (max-width: 768px) {
        padding: 0 1%;
        text-align: center;
      }
    }
    .spd-career-data-title {
      width: 100%;
      color: #fdff97;
      text-align: left;
      font-family: "Inria Sans", sans-serif;
      padding-bottom: 1%;
      font-size: 2.05em;
      letter-spacing: 0.03em;
      margin-top: 1%;
      text-decoration: underline;
    }
    .spd-grid-container {
      padding: 1% 0;
      .spd-grid-row {
        display: flex;
        justify-content: center;
        .spd-grid-col {
          display: flex;
          margin: 1% 2%;
          gap: 2%;
          border-radius: 8px;
          padding: 1.5% 2%;
          background-color: rgb(45, 49, 53);
          @media screen and (max-width: 768px) {
            padding: 3.5% 2%;
            margin: 3% 0%;
            width: 80%;
          }
          .spd-grid-button {
            font-size: 1.05rem;
            color: rgba(168, 238, 113, 1);
            border: none;
            width: 100%;
            background-color: transparent;
            &:hover {
              color: #fdff97;
              border: none;
              transform: scale(1.12);
            }
            &:active {
              transform: scale(1);
            }
          }
          .spd-grid-title {
            font-size: 1.05rem;
            color: rgba(168, 238, 113, 1);
          }
        }
        .spd-grid-col-centered {
          display: flex;
          margin: 1% 2%;
          gap: 2%;
          border-radius: 8px;
          padding: 1.5% 2%;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: rgb(45, 49, 53);
          @media screen and (max-width: 768px) {
            padding: 3.5% 2%;
            margin: 3% 0%;
            width: 80%;
          }
          .spd-download-button {
            width: 18.5rem;
            height: 3.7rem;
            background: #eef08d;
            border-radius: 6px;
            font-weight: 300;
            font-size: 1.25rem;
            letter-spacing: 0.02em;
            color: #2b2c31;
            border: none;
            &:hover {
              background: linear-gradient(
                -90deg,
                rgba(255, 214, 107) 0%,
                rgb(164, 164, 164) 100%
              );
              color: black;
              transform: scale(1.1);
            }
            &:active {
              transform: scale(1);
            }
          }
        }
      }
    }
  }
}
