.careers-home-main-container {
  width: 100%;
  padding: 1%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #212529;
  .careers-home-add-button-container {
    width: 100%;
    padding: 1%;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
  }
  .careers-home-table-container {
    padding: 1%;
    width: 100%;
    border: 1px solid #fdff97;
    border-radius: 15px;
    .careers-home-table {
      --bs-table-border-color: #fdff97;
      --bs-table-hover-bg: rgba(226, 227, 231, 0.52);
      --bs-table-hover-color: black;
      .careers-home-column-title {
        text-align: center;
        font-size: 1.15em;
        font-family: "Inria Sans";
        padding: 1%;
      }
      .careers-home-column-data {
        padding: 1% 0%;
        text-align: center;
        font-size: 1em;
        font-family: "Inria Sans";
        padding: 1%;
      }
    }
  }
}
