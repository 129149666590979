.sale-status-main-container {
  background-color: #212529;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 2%;
}

body > div.swal2-container.swal2-center.swal2-backdrop-show > div {
  border: 4px solid #eef08d;
  display: grid;
  place-items: center;
  .success-sale-title {
    font-family: "Inria Sans";
    color: rgba(168, 238, 113, 1);
    font-size: 2.3rem;
  }
  .success-sale-icon {
    padding: 0px;
    margin: 10px;
  }
  .success-sale-image {
    margin: 0px;
  }
  .success-sale-text {
    font-family: "Inria Sans";
    color: white;
    font-size: 1.2rem;
  }
  .success-sale-button {
    color: rgba(253, 255, 151, 1);
    background: #383b42;
    border: none;
    padding: 0.55rem 4.5rem;
    &:hover {
      background: linear-gradient(
        45deg,
        rgba(167, 238, 113, 0.686) 0%,
        rgba(253, 255, 151, 1) 100%
      );
      color: black;
      transform: scale(1.1);
    }
  }
}

body > div.swal2-container.swal2-center.swal2-backdrop-show > div {
  /* border: 3px solid #f8bb86; */
  border: 4px solid #eef08d;
  display: grid;
  place-items: center;
  .pending-sale-title {
    font-family: "Inria Sans";
    color: rgb(255, 199, 149);
    font-size: 2.3rem;
  }
  .pending-sale-icon {
    padding: 0px;
    margin: 10px;
  }
  .pending-sale-image {
    margin: 0px;
  }
  .pending-sale-text {
    font-family: "Inria Sans";
    color: white;
    font-size: 1.2rem;
  }
  .pending-sale-button {
    color: rgba(253, 255, 151, 1);
    background: #383b42;
    border: none;
    padding: 0.55rem 4.5rem;
    &:hover {
      background: linear-gradient(
        45deg,
        rgb(255, 199, 149) 30%,
        rgba(253, 255, 151, 1) 100%
      );
      color: black;
      transform: scale(1.1);
    }
  }
}

body > div.swal2-container.swal2-center.swal2-backdrop-show > div {
  border: 4px solid #eef08d;
  display: grid;
  place-items: center;
  .failure-sale-title {
    font-family: "Inria Sans";
    color: rgb(245, 132, 132);
    font-size: 2.3rem;
  }
  .failure-sale-icon {
    padding: 0px;
    margin: 10px;
  }
  .failure-sale-image {
    margin: 0px;
  }
  .failure-sale-text {
    font-family: "Inria Sans";
    color: white;
    font-size: 1.2rem;
  }
  .failure-sale-button {
    color: rgba(253, 255, 151, 1);
    background: #383b42;
    border: none;
    padding: 0.55rem 4.5rem;
    &:hover {
      background: linear-gradient(
        45deg,
        rgb(245, 132, 132) 25%,
        rgba(253, 255, 151, 1) 100%
      );
      color: black;
      transform: scale(1.1);
    }
  }
}
