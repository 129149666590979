.create-sale-main-container {
  background-color: #212529;
  display: flex;
  justify-content: center;
  flex-direction: column;
  .create-sale-title-container {
    width: 100%;
    padding: 2%;
    text-align: center;
    .create-sale-title {
      color: #fdff97;
      font-family: "Inria Sans";
      font-size: 35px;
      line-height: 42px;
    }
  }
  .create-sale-data-container {
    width: 100%;
    display: flex;
    padding: 0px;
    justify-content: center;
    .create-sale-grid-row {
      width: 100%;
      .create-sale-grid-item {
        .create-sale-grid-item-data {
          .create-sale-inner-grid-row {
            margin: 7% 0%;
            .item-name {
              text-align: center;
              background: radial-gradient(
                50% 50% at 50% 50%,
                #bef394 0%,
                #d8f8e1 8.25%,
                #fdff97 100%
              );
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              background-clip: text;
              font-family: "Inria Sans";
              font-size: 30px;
              line-height: 42px;
              letter-spacing: 0.018rem;
            }
            .create-sale-inner-col {
              .item-data-card {
                border: 1px solid white;
                border-radius: 8px;
                display: flex;
                flex-direction: column;
                padding: 0% 0% 3% 0%;
                .item-data {
                  color: white;
                  text-align: center;
                  margin-top: 1%;
                }
                .item-start-date {
                  text-align: center;
                  background: radial-gradient(
                    50% 50% at 50% 50%,
                    #bef394 0%,
                    #d8f8e1 8.25%,
                    #fdff97 100%
                  );
                  -webkit-background-clip: text;
                  -webkit-text-fill-color: transparent;
                  background-clip: text;
                  font-family: "Inria Sans";
                  font-size: 20px;
                  line-height: 42px;
                  padding: 1% 0%;
                }
              }
              .item-duration {
                text-align: center;
                background: radial-gradient(
                  50% 50% at 50% 50%,
                  #bef394 0%,
                  #d8f8e1 8.25%,
                  #fdff97 100%
                );
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                font-family: "Inria Sans";
                font-size: 20px;
                line-height: 42px;
                padding: 1% 0%;
              }
              .item-requirements {
                text-align: center;
                background: radial-gradient(
                  50% 50% at 50% 50%,
                  #bef394 0%,
                  #d8f8e1 8.25%,
                  #fdff97 100%
                );
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                font-family: "Inria Sans";
                letter-spacing: 0.025rem;
                font-size: 20px;
                line-height: 42px;
                padding: 1% 0%;
              }
              .item-introduction {
                text-align: center;
                background: radial-gradient(
                  50% 50% at 50% 50%,
                  #bef394 0%,
                  #d8f8e1 8.25%,
                  #fdff97 100%
                );
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                letter-spacing: 0.025rem;
                font-family: "Inria Sans";
                font-size: 20px;
                line-height: 42px;
                padding: 1% 0%;
              }
              .item-data-card-price {
                padding: 0% 2%;
                .item-price {
                  text-align: end;
                  background: radial-gradient(
                    50% 50% at 50% 50%,
                    #bef394 0%,
                    #d8f8e1 8.25%,
                    #fdff97 100%
                  );
                  -webkit-background-clip: text;
                  -webkit-text-fill-color: transparent;
                  background-clip: text;
                  font-family: "Inria Sans";
                  font-size: 20px;
                  line-height: 42px;
                  letter-spacing: 0.025rem;
                  padding: 1% 0%;
                }
              }
            }
          }
        }
      }
    }
  }
}
