.address-map-container {
  width: 50%;
  z-index: 0;
  @media screen and (max-width: 768px) {
    width: 80%;
  }
  display: flex;
  justify-content: center;
  #map {
    height: 338px;
    width: 500px;
    max-width: 750px;
  }
  .map-container {
    div > div {
      background-color: transparent;
      div > a > span {
        background-color: transparent;
      }
      div > a {
        &:hover {
          background-color: #f5f5f5ae;
        }
      }
    }
    div.leaflet-bottom.leaflet-right > div > a {
      background-color: transparent;
    }
  }
  .market-icon {
    background: transparent;
  }
  .ubicacion-container {
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: center;
    background-color: #212529;
  }
}
