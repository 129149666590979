.custom-carrusel-main-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  .carousel-control-prev-icon{
    background-color: transparent;
  }
  .carousel-control-next-icon{
    background-color: transparent;
  }
  > div {
    width: 60%;
    @media screen and (max-width: 768px) {
      width: 100%;
    }
    margin-top: 0px;
  }
}
