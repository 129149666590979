.subtitle {
  text-align: start;
  color: white;
  font-family: "Inria Sans";
  font-style: normal;
  font-weight: 300;
  font-size: 30px;
  line-height: 36px;
  padding-top: 30px;
}
.buttons-container {
  display: flex;
  gap: 8px;
  .button-group {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    span {
      font-family: "Inria Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 7px;
      line-height: 6px;
      color: #ef9797;
    }
  }
  button {
    border-radius: 4px;
    font-family: "Inria Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    padding: 9px 14px;
    border: none;
    text-decoration: none;
  }
  button:disabled {
    background-color: #3d3f43;
    color: #ffffff;
    text-decoration: none;
    line-height: 18px;
  }
  .btnBarberia {
    background-color: #eef08d;
    margin-top: 6px;
    color: black;
    text-decoration: none;
    &:active,
    &:hover {
      color: black;
    }
  }
}
