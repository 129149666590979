.products-container {
  width: 100%;
  padding: 2rem 0 0 0;
  text-align: center;
  .products-title-container {
    display: flex;
    justify-content: flex-start;
    padding: 0 5rem;
    @media screen and (max-width: 768px) {
      padding: 0 1rem;
    }
    .products-title {
      color: white;
      font-family: "Inria Sans";
      font-style: normal;
      font-weight: 700;
      font-size: 2.5rem;
      line-height: 42px;
    }
  }
  .products-inner-container {
    padding-bottom: 2rem;
    @media screen and (max-width: 768px) {
      padding-bottom: 0.51rem;
    }
    background: url("../../../../assets/logos_barber_ultimo.png");
    background-position: 0 auto;
    background-repeat: no-repeat;
    background-size: cover;
    object-fit: fill;
    .products-header-container {
      padding: 0 5rem;
      @media screen and (max-width: 768px) {
        padding: 0 1rem;
      }
    }
    .slide {
      width: 100%;
      /* padding-bottom: 3.2rem;
      background: url("../../../../assets/img_logos_grande.png");
      background-repeat: no-repeat;
      background-size: cover;
      object-fit: cover; */
    }
  }
}
