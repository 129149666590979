.product-detail-main-container {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 2% 7%;
  background-color: #212529;
  .product-detail-title-container {
    padding: 0% 3.5%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    .product-detail-index {
      font-family: "Inria Sans";
      font-weight: 400;
      font-size: 1.5rem;
      line-height: 26px;
      background: radial-gradient(
        50% 50% at 50% 50%,
        #bef394 0%,
        #ff97ee 6.25%,
        #fdff97 100%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
    }
    .product-detail-title {
      font-family: "Inria Sans";
      margin-top: 1.2%;
      font-weight: 700;
      font-size: 2rem;
      line-height: 42px;
      color: #fff;
    }
  }
  .product-detail-disc {
    margin-top: 1%;
    padding: 0 3.5%;
    .product-detail-disc-list {
      font-weight: 400;
      font-size: 0.9rem;
      letter-spacing: 0.01em;
      color: #bebfc2;
      .product-detail-disc-list-item {
        padding-bottom: 0.3rem;
      }
    }
    .product-detail-item-quota {
      color: rgba(168, 238, 113, 1);
      font-size: 1.3rem;
      letter-spacing: 0.05rem;
      margin-bottom: 1.8rem;
    }
    .product-detail-item-quota-unavailable {
      color: rgb(254, 106, 106);
      font-size: 1.3rem;
      letter-spacing: 0.05rem;
      margin-bottom: 1.8rem;
    }
  }

  .product-detail-buttons-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0 3.5%;
    .product-detail-add-button {
      width: 18.5rem;
      height: 3.7rem;
      background: #eef08d;
      border-radius: 6px;
      font-weight: 300;
      font-size: 1.25rem;
      letter-spacing: 0.02em;
      color: #2b2c31;
      border: none;
      &:hover {
        background: linear-gradient(
          -90deg,
          rgba(255, 214, 107) 0%,
          rgb(164, 164, 164) 100%
        );
        color: black;
        transform: scale(1.1);
      }
      &:active {
        transform: scale(1);
      }
    }
    .product-detail-add-button-disabled {
      width: 18.5rem;
      height: 3.7rem;
      background: #eef08d6f;
      border-radius: 6px;
      font-weight: 300;
      font-size: 1.25rem;
      line-height: 24px;
      letter-spacing: 0.02em;
      color: #2b2c31;
    }
    .product-detail-sale-warning {
      margin-top: 1%;
      color: #fec3c3;
    }
  }
  .product-detail-about {
    display: flex;
    flex-direction: column;
    margin-top: 4%;
    @media screen and (max-width: 768px) {
      margin-top: 15%;
    }
    margin-bottom: 26px;
    .product-detail-about-title-container {
      padding: 0 3.5%;
      .product-detail-about-title {
        font-weight: 400;
        font-size: 2.5rem;
        line-height: 42px;
        color: #fff;
        @media screen and (max-width: 768px) {
          margin-bottom: 10%;
        }
      }
      .product-detail-accordion-container {
        margin-top: 2.5%;
        @media screen and (max-width: 768px) {
          margin-bottom: 10%;
        }
      }
    }
    .product-detail-info-container {
      margin: 3% 0;
      padding: 1% 3.5%;
      .product-detail-info-item {
        padding: 0.5% 0;
        display: flex;
        align-items: center;
        margin-bottom: 1.65rem;
        background-color: #2b2c31;
        border-radius: 7px;
        .product-detail-info-item-img-container {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 1.5rem 1.6rem;
          .product-detail-info-item-img {
            width: 1.5rem;
          }
        }
        .product-detail-info-item-description {
          width: 100%;
          border-left: 0.3px solid #000000;
          padding: 0.7rem 1.21rem;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          font-size: 1rem;
          color: #fff;
        }
      }
      .product-detail-info-item-program {
        padding: 0.5% 0;
        display: flex;
        align-items: center;
        background-color: #2b2c31;
        border-radius: 7px;
        cursor: pointer;
        .product-detail-info-item-img-container {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 1.5rem 1.6rem;
          .product-detail-info-item-img {
            width: 1.5rem;
          }
        }
        .product-detail-info-item-description {
          width: 100%;
          border-left: 0.3px solid #000000;
          padding: 0.7rem 1.21rem;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          font-size: 1rem;
          color: #fff;
        }
      }
    }
  }
}
