.barber-tools-container {
  display: flex;
  flex-direction: column;
  gap: 50%;
  align-items: center;
  margin-top: 50px;
  padding-right: 15px;
  padding-left: 15px;
}
.maquinas1-container {
  width: 100%;
  gap: 30px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  h1 {
    font-family: "Alumni Sans", sans-serif;
    font-weight: 900;
    font-size: 40px;
    line-height: 48px;
    text-align: center;
    span {
      color: #ffd66b;
    }
  }
}
.maquinas2-container {
  display: flex;
  align-items: center;
  background-color: #212529;
  color: white;
  gap: 30px;
  .sub-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    h1 {
      font-family: "Alumni Sans", sans-serif;
      font-weight: 900;
      font-size: 40px;
      line-height: 48px;
      text-align: center;
    }
    .all-tools-button {
      margin-top: 2rem;
      border: 1px solid #ffd66b;
      padding: 1.2rem;
      border-radius: 8px;
      color: white;
      text-decoration: none;
      font-size: 1.12rem;
      background-color: #353535;
      &:hover {
        background: linear-gradient(
          90deg,
          rgba(255, 214, 107) 0%,
          rgba(253, 255, 151, 1) 100%
        );
        color: black;
        transform: scale(1.1);
      }
    }
  }
}
