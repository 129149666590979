.sales-form-main-container {
  padding: 8% 0%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .sales-form-title-container {
    width: 100%;
    display: flex;
    justify-content: center;
    .sales-form-title {
      background: radial-gradient(
        50% 50% at 50% 50%,
        #bef394 0%,
        #d8f8e1 8.25%,
        #fdff97 100%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      font-family: "Inria Sans";
    }
  }
  .sales-form-grid-container {
    .sales-form {
      border: 1px solid #fdff97;
      border-radius: 15px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 2% 0%;
      .sales-form-grid-row {
        width: 100%;
        margin: 1% 0%;
        .sales-form-grid-item {
          display: flex;
          justify-content: center;
          flex-direction: column;
          .sales-form-input-label {
            font-size: 1.15rem;
            letter-spacing: 0.05rem;
            font-family: "Inria Sans", sans-serif;
            color: #fdff97;
            text-align: start;
          }
          .sales-form-input {
            width: 75vw;
            min-width: 100%;
            padding: 2%;
            font-size: 1.1rem;
            font-family: "Inria Sans", sans-serif;
          }
          .sales-form-select {
            width: 75vw;
            min-width: 100%;
            font-size: 1.1rem;
            font-weight: 400;
            line-height: 1.5;
            font-family: "Inria Sans", sans-serif;
            .css-13cymwt-control {
              border-radius: 7px;
              height: 2.7em;
            }
          }
          .sales-form-input-error {
            padding: 0% 0.85%;
            margin-top: 0.8%;
            text-align: start;
            font-family: "Inria Sans", sans-serif;
            color: red;
          }
          .sales-form-uses-tools {
            display: flex;
            padding-top: 0px;
            align-items: center;
            width: 100%;
            .sales-check-switch {
              width: 4em;
              margin: 0px 0px 0px -2.5em;
              &:checked {
                background-color: #fdff97;
                background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba(0,0,0,0.25)'/></svg>");
              }
              &:focus {
                border-color: none;
              }
            }
            > label {
              padding: 0px;
              margin-left: 2%;
            }
          }
          .sales-form-first-installment {
            display: flex;
            padding-top: 0px;
            align-items: center;
            width: 100%;
            .sales-first-installment-check-switch {
              width: 2.5em;
              margin: 0px 0px 0px -2.5em;
              &:checked {
                background-color: #fdff97;
                background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba(0,0,0,0.25)'/></svg>");
              }
              &:focus {
                border-color: none;
              }
            }
            > label {
              padding: 0px;
              margin-left: 2%;
            }
          }
        }
      }
      .sales-form-submit-btn-container {
        width: 100%;
        margin-top: 3%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .sales-form-back-btn {
          font-family: "Inria Sans", sans-serif;
          font-size: 1.12em;
          border: 1px solid#d2bead;
          border-radius: 15px;
          background: transparent;
          color: #d2bead;
          padding: 2.5% 5%;
          margin: 1% auto;
          /* min-width: 18vw;
          max-width: 22vw; */
          &:hover {
            transform: scale(1.05);
            background-color: #d2bead;
            color: #212529;
          }
          &:active {
            transform: scale(0.95);
          }
        }
        .sales-form-submit-btn {
          font-family: "Inria Sans", sans-serif;
          font-size: 1.12em;
          border: 1px solid #b0f2c2;
          border-radius: 15px;
          background: transparent;
          color: #b0f2c2;
          padding: 2.5% 5%;
          margin: 1% auto;
          /* min-width: 18vw;
          max-width: 22vw; */
          &:hover {
            transform: scale(1.05);
            background-color: #b0f2c2;
            color: #212529;
          }
          &:active {
            transform: scale(0.95);
          }
        }
        .sales-form-submit-btn-disabled {
          font-family: "Inria Sans", sans-serif;
          font-size: 1.12em;
          border: 1px solid #c6c6c6;
          border-radius: 15px;
          background: transparent;
          color: #c6c6c6;
          padding: 2.5% 5%;
          margin: 1% auto;
        }
      }
    }
  }
}
