.login-selector-main-container {
  background-color: #212529;
  display: flex;
  justify-content: center;
  flex-direction: column;
  .login-selector-title-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1% 0%;
    .login-selector-title {
      color: white;
      font-size: 2em;
      font-family: "Inria Sans";
      background: radial-gradient(
        50% 50% at 50% 50%,
        #bef394 0%,
        #d8f8e1 8.25%,
        #fdff97 100%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
    }
  }
  .login-selector-buttons-container {
    padding: 1% 2%;
    margin: 0% 0% 2% 0%;
    .login-selector-grid {
      padding: 0% 2%;
      .login-selector-row {
        .login-selector-column {
          display: flex;
          justify-content: center;
          align-items: center;
          height: auto;
          @media screen and (max-width: 768px) {
            margin: 8% 0%;
          }
          .login-selector-sellers-button {
            width: 85%;
            height: 45vh;
            display: flex;
            justify-content: center;
            align-items: center;
            background: linear-gradient(
                to top,
                rgba(0, 0, 0, 0.465) 0,
                rgba(0, 0, 0, 0.6) 50%,
                rgba(0, 0, 0, 0.465) 100%
              ),
              url("../../../../assets/vendedores.jpg");
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            cursor: pointer;
            &:hover {
              box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px,
                rgba(0, 0, 0, 0.23) 0px 3px 6px;
            }
            .login-selector-button-text-container {
              width: 100%;
              height: 100%;
              padding: 3% 0%;
              display: flex;
              justify-content: center;
              align-items: center;
              color: white;
              &:hover {
                backdrop-filter: blur(4px);
                transform: scale(1.012);
              }
              .login-selector-button-text {
                font-family: "Inria Sans";
                font-size: 2.2em;
                color: white;
                width: 100%;
                text-align: center;
                &:hover {
                  color: #fdff97;
                }
              }
            }
          }
          .login-selector-students-button {
            width: 85%;
            height: 45vh;
            display: flex;
            justify-content: center;
            align-items: center;
            background: linear-gradient(
                to top,
                rgba(0, 0, 0, 0.465) 0,
                rgba(0, 0, 0, 0.6) 50%,
                rgba(0, 0, 0, 0.465) 100%
              ),
              url("../../../../assets/estudiantes.jpg");
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            cursor: pointer;
            &:hover {
              box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px,
                rgba(0, 0, 0, 0.23) 0px 3px 6px;
            }
            .login-selector-button-text-container {
              width: 100%;
              height: 100%;
              padding: 3% 0%;
              display: flex;
              justify-content: center;
              align-items: center;
              color: white;
              &:hover {
                backdrop-filter: blur(4px);
                transform: scale(1.012);
              }
              .login-selector-button-text {
                font-family: "Inria Sans";
                font-size: 2.2em;
                color: white;
                width: 100%;
                text-align: center;
                &:hover {
                  color: #fdff97;
                }
              }
            }
          }
        }
      }
    }
  }
}
