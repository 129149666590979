.login-header-main-container {
  width: 100%;
  padding: 0px;
  margin: 0px;
  background-color: #212529;
  .login-header-inner-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 0px;
    margin: 0px;
  }
}
