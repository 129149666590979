.navbar-main-container {
  background-color: #1f2023;
  border-bottom: solid 1px #eef08d;
  --bs-navbar-toggler-icon-bg: url("../../../assets/icons/bars-solid.svg");
  --bs-navbar-active-color: #eef08d;
  .navbar-inner-container {
    display: flex;
    justify-content: space-between;
    .navbar-inner-container-item-logo {
      width: 33%;
      .navbar-logo-img {
        @media screen and (max-width: 768px) {
          width: 7em;
        }
      }
    }
    .navbar-inner-container-item-nav {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      @media screen and (max-width: 768px) {
        justify-content: center;
      }
    }
  }
}

.navbar-nav {
  display: flex;
  align-items: center;
}
.navbar-toggler:focus {
  color: #eef08d;
}
.nav-item {
  padding: 8px;
  font-size: 16px;
  color: #ffffff;
  :focus {
    color: #eef08d;
  }
  a {
    text-decoration: none;
    color: #ffffff;
    &:hover {
      color: #eef08d;
    }
  }
}

.nav-login-buttons-container {
  display: flex;
  @media screen and (max-width: 768px) {
    justify-content: center;
  }
  .nav-login-button {
    border: none;
    padding: 8px;
    background-color: transparent;
    color: #fff;
    &:hover {
      color: #eef08d;
      transform: scale(1.02);
    }
    &:active {
      transform: scale(0.9);
    }
  }
}

.dropdown-menu {
  --bs-dropdown-bg: #212529;
  --bs-dropdown-border-color: #eef08d;
  --bs-dropdown-link-hover-bg: transparent;
  --bs-dropdown-link-active-bg: #eef08d;
}

.link-unstyled {
  text-decoration: none;
}
