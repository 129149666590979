.students-form-main-container {
  padding: 2%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .students-form-grid-container {
    border-top: 2%;

    .students-form {
      border: 1px solid #fdff97;
      border-radius: 15px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 2% 0%;

      .students-form-grid-row {
        width: 100%;
        margin: 1% 0%;

        .students-form-grid-item {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;

          .students-form-input-label {
            font-size: 1.35rem;
            letter-spacing: 0.2rem;
            font-family: "Inria Sans", sans-serif;
            color: #fdff97;
            width: 50%;
          }

          .students-form-input {
            padding: 2%;
            font-size: 1.1rem;
            font-family: "Inria Sans", sans-serif;
          }

          .students-form-input-error {
            padding: 0% 0.85%;
            margin-top: 0.8%;
            text-align: start;
            font-family: "Inria Sans", sans-serif;
            color: red;
          }

          .psw-button {
            border: none;
            border-radius: 5px;
            width: 15%;
            padding: 1.5% 1.5%;
          }

          .student-form-status-switch {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-left: 0;
            .student-form-switch {
              width: 3.5em;
              height: 1.8em;
              margin: 0;
              &:checked {
                background-color: #fdff97;
                background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba(0,0,0,0.25)'/></svg>");
              }
              &:focus {
                border-color: none;
              }
            }
            > label {
              padding: 0px;
              text-align: start;
            }
          }
        }
      }

      .students-form-sumbit-btn-container {
        margin: 3% 0%;
        width: 50%;
        display: flex;
        justify-content: space-between;

        .students-form-submit-btn {
          font-family: "Inria Sans", sans-serif;
          font-size: 1.12em;
          border: 1px solid #b0f2c2;
          border-radius: 15px;
          background: transparent;
          color: #b0f2c2;
          padding: 2%;
          margin: 1% auto;
          // width: 25%;
          max-width: 18vw;
          min-width: 15vw;

          &:hover {
            transform: scale(1.05);
            background-color: #b0f2c2;
            color: #212529;
          }

          &:active {
            transform: scale(0.95);
          }
        }

        .students-form-back-btn {
          font-family: "Inria Sans", sans-serif;
          font-size: 1.12em;
          border: 1px solid#d2bead;
          border-radius: 15px;
          background: transparent;
          color: #d2bead;
          padding: 2%;
          margin: 1% auto;
          // width: 25%;
          max-width: 18vw;
          min-width: 15vw;

          &:hover {
            transform: scale(1.05);
            background-color: #d2bead;
            color: #212529;
          }

          &:active {
            transform: scale(0.95);
          }
        }
      }
    }
  }
}
