.footer-main-container {
  margin-top: auto;
  background-color: #2b2c31;
  color: white;
  font-family: "Inria Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 29px;
  display: flex;
  justify-content: space-between;
  padding: 10px 85px;
  @media screen and (max-width: 768px) {
    padding-right: 82px;
    padding-left: 15px;
  }
  .footer-admin-link {
    display: flex;
    align-items: center;
    font-size: 15px;
    letter-spacing: 0.08rem;
    color: #eef08d;
    cursor: pointer;
    &:hover {
      color: white;
    }
  }
  .icons-container {
    display: flex;
    align-items: center;
    gap: 15px;
    img {
      width: 18px;
      height: auto;
    }
  }
}
