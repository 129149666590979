.onboarding-main-container {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 2% 0;
  background-color: #212529;
  .onboarding-title-container {
    width: 100%;
    padding: 2% 0;
    text-align: center;
    .onboarding-title {
      color: #fdff97;
      font-family: "Inria Sans";
      font-size: 35px;
      line-height: 42px;
    }
  }
  .onboarding-form-container {
    display: flex;
    justify-content: center;
    margin-top: 1%;
    .onboarding-form {
      border: 1px solid #fdff97;
      border-radius: 15px;
      @media screen and (max-width: 768px) {
        width: 100%;
      }
      width: 60%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 2% 0%;
      .onboarding-form-row {
        width: 90%;
        margin: 2% 0%;
        .onboarding-form-item {
          display: flex;
          justify-content: center;
          flex-direction: column;
          .onboarding-form-label {
            font-size: 1.15rem;
            letter-spacing: 0.05rem;
            font-family: "Inria Sans", sans-serif;
            color: #fdff97;
            text-align: start;
          }
          .onboarding-form-input {
            width: 75vw;
            min-width: 100%;
            padding: 2%;
            font-size: 1.1rem;
            font-family: "Inria Sans", sans-serif;
          }
          .onboarding-form-psw-input {
            width: 80vw;
            padding: 2%;
            @media screen and (max-width: 768px) {
              width: 100%;
            }
            font-size: 1.1rem;
            font-family: "Inria Sans", sans-serif;
          }
          .onboarding-form-psw-button {
            border: none;
            border-radius: 5px;
            width: 15%;
            padding: 1.5% 5px;
            background-color: transparent;
          }
          .onboarding-form-input-error {
            padding: 0% 0.85%;
            margin-top: 0.8%;
            text-align: start;
            font-family: "Inria Sans", sans-serif;
            color: red;
          }
        }
      }
      .onboarding-form-buttons-container {
        width: 60%;
        padding-top: 2%;
        margin: 2% 0;
        display: flex;
        justify-content: space-around;
        align-items: center;
        .onboarding-form-submit-button {
          font-family: "Inria Sans", sans-serif;
          font-size: 1.12em;
          border: 1px solid #b0f2c2;
          border-radius: 15px;
          background: transparent;
          color: #b0f2c2;
          padding: 2.5% 5%;
          margin: 1% auto;
          @media screen and (max-width: 768px) {
            margin-top: 10%;
            min-width: 70%;
          }
          &:hover {
            transform: scale(1.05);
            background-color: #b0f2c2;
            color: #212529;
          }
          &:active {
            transform: scale(0.95);
          }
        }
      }
    }
  }
}
