.debts-form-main-container {
  padding: 1%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .debts-form-grid-container {
    .debts-form {
      border: 1px solid #fdff97;
      border-radius: 15px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 2% 0%;
      .debts-form-grid-row {
        width: 100%;
        margin: 2% 0%;
        .debts-form-grid-item {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          .debts-form-input-label {
            font-size: 1.35rem;
            letter-spacing: 0.2rem;
            font-family: "Inria Sans", sans-serif;
            color: #fdff97;
          }
          .debts-form-select {
            @media screen and (max-width: 768px) {
              width: 100%;
            }
            width: 50%;
            font-size: 1.1rem;
            font-family: "Inria Sans", sans-serif;
          }
          .debts-form-input {
            border: 2px solid white;
            padding: 2%;
            font-size: 1.1rem;
            font-family: "Inria Sans", sans-serif;
          }
          .debts-form-input-error {
            padding: 0% 0.85%;
            margin-top: 0.8%;
            text-align: start;
            font-family: "Inria Sans", sans-serif;
            color: red;
          }
        }
      }
      .debts-form-buttons-container {
        margin: 3% 0%;
        width: 50%;
        display: flex;
        justify-content: space-between;
        .debts-form-back-btn {
          font-family: "Inria Sans", sans-serif;
          font-size: 1.12em;
          border: 1px solid#d2bead;
          border-radius: 15px;
          background: transparent;
          color: #d2bead;
          padding: 2%;
          margin: 1% auto;
          min-width: 15vw;
          max-width: 18vw;
          &:hover {
            transform: scale(1.05);
            background-color: #d2bead;
            color: #212529;
          }
          &:active {
            transform: scale(0.95);
          }
        }
        .debts-form-submit-btn {
          font-family: "Inria Sans", sans-serif;
          font-size: 1.12em;
          border: 1px solid #b0f2c2;
          border-radius: 15px;
          background: transparent;
          color: #b0f2c2;
          padding: 2%;
          margin: 1% auto;
          min-width: 15vw;
          max-width: 18vw;
          &:hover {
            transform: scale(1.05);
            background-color: #b0f2c2;
            color: #212529;
          }
          &:active {
            transform: scale(0.95);
          }
        }
      }
    }
  }
}
