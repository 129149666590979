.profile-detail-main-container {
  //   border: 2px red solid;
  background-color: #212529;
  color: #ffffff;
  font-family: "Inria Sans";
  font-style: normal;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  hr {
    max-width: 930px;
  }
  .profile-detail-modal-alert {
    width: 80%;
    justify-content: center;
    .full-width-button {
      display: block;
      width: 100%;
      font-weight: bolder;
    }
  }
  .profile-detail-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 930px;
  }
  .profile-detail-title {
    // border: 2px blue solid;
    width: 100%;
    font-weight: 700;
    font-size: 35px;
    line-height: 42px;
  }
  .student-detail-my-courses {
    // border: 2px red solid;
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 20px;
    font-weight: 400;
    font-size: 35px;
    line-height: 42px;
    margin-top: 30px;
    margin-bottom: 26px;
    // .student-detail-my-courses-accordion {
    //   border: 2px solid orange;
    // }
  }
  .student-profile-table-title {
    width: 100%;
    color: #fdff97;
    text-align: left;
    font-family: "Inria Sans", sans-serif;
    padding-bottom: 1%;
    font-size: 2.05em;
    letter-spacing: 0.03em;
    text-shadow: -2px 3px 3px rgba(255, 255, 255, 0.261);
    margin-top: 1%;
    text-decoration: underline;
  }
  .student-profile-table-container {
    padding: 1%;
    width: 100%;
    border: 1px solid #fdff97;
    border-radius: 15px;

    .student-profile-table {
      --bs-table-border-color: #fdff97;
      --bs-table-hover-bg: rgba(226, 227, 231, 0.52);
      --bs-table-hover-color: black;

      .student-profile-column-title {
        text-align: center;
        font-size: 1.15em;
        font-family: "Inria Sans";
        padding: 1%;
      }

      .student-profile-column-data-course {
        // border: 2px solid red;
        padding: 1% 0%;
        width: 30%;
        text-align: left;
        font-size: 1em;
        font-family: "Inria Sans";
        padding: 1%;
        align-items: center;
      }
      .student-profile-column-startdate-course {
        padding: 1% 0%;
        width: 30%;
        text-align: center;
        font-size: 1em;
        font-family: "Inria Sans";
      }
      .student-profile-column-data-progress {
        // border: 2px solid blue;
        align-items: center;
        text-align: center;
        font-family: "Inria Sans";
        .student-profile-detail-button {
          padding: 0.5rem;
          border: none;
          color: white;
          text-decoration: none;
          font-size: 1em;
          background-color: transparent;
          &:hover {
            background: linear-gradient(
              90deg,
              rgba(255, 214, 107) 0%,
              rgba(253, 255, 151, 1) 100%
            );
            color: black;
            transform: scale(1.1);
          }
        }
      }
    }
  }
  .student-profile-certificates-container {
    margin-top: 3%;
    width: 100%;
    display: flex;
    flex-direction: column;
    .student-profile-certificates {
      margin-top: 1%;
      border: 1px solid #fdff97;
      border-radius: 15px;
      padding: 3%;
      display: flex;
      justify-content: space-around;
      @media screen and (max-width: 768px) {
        flex-direction: column;
      }
      .student-profile-diploma-container {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        @media screen and (max-width: 768px) {
          margin: 3% 0;
        }
        .diploma-download-button {
          width: 18.5rem;
          height: 3.7rem;
          background: #eef08d;
          border-radius: 6px;
          font-weight: 300;
          font-size: 1.25rem;
          letter-spacing: 0.02em;
          color: #2b2c31;
          border: none;
          &:hover {
            background: linear-gradient(
              -90deg,
              rgba(255, 214, 107) 0%,
              rgb(164, 164, 164) 100%
            );
            color: black;
            transform: scale(1.1);
          }
          &:active {
            transform: scale(1);
          }
        }
      }
      .student-profile-carnet-container {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        @media screen and (max-width: 768px) {
          margin: 3% 0;
        }
        .carnet-download-button {
          width: 18.5rem;
          height: 3.7rem;
          background: #eef08d;
          border-radius: 6px;
          font-weight: 300;
          font-size: 1.25rem;
          letter-spacing: 0.02em;
          color: #2b2c31;
          border: none;
          &:hover {
            background: linear-gradient(
              -90deg,
              rgba(255, 214, 107) 0%,
              rgb(164, 164, 164) 100%
            );
            color: black;
            transform: scale(1.1);
          }
          &:active {
            transform: scale(1);
          }
        }
      }
    }
  }
}
