.course-delete-modal {
  .modal-dialog {
    .modal-content {
      border: 2px solid black;
      border-radius: 25px;
      background-color: #fdff97;
      .course-delete-modal-header {
        background-color: transparent;
        border-color: black;
        width: 100%;
        display: flex;
        justify-content: center;
        .course-delete-modal-title {
          background-color: transparent;
          color: #212529;
          text-align: center;
          font-family: "Inria Sans", sans-serif;
          padding-bottom: 1%;
          font-size: 2.05em;
          letter-spacing: 0.03em;
          text-shadow: -2px 3px 3px rgba(57, 57, 57, 0.261);
        }
      }
      .course-delete-modal-body {
        background-color: transparent;
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        .course-delete-modal-body-data {
          background-color: transparent;
          text-align: center;
          color: black;
          font-size: 1.25em;
          font-family: "Inria Sans", sans-serif;
        }
      }
      .delete-course-modal-footer {
        border: none;
        background-color: transparent;
        .course-delete-modal-buttons {
          font-family: "Inria Sans", sans-serif;
          font-size: 1.12em;
          border: 1px solid#2a2a2a;
          border-radius: 15px;
          background: transparent;
          color: #2a2a2a;
          padding: 2%;
          margin: 1% auto;
          min-width: 25vw;
          max-width: 26vw;
          &:hover {
            transform: scale(1.05);
            background-color: #828282;
            /* color: #212529; */
            color: white;
          }
          &:active {
            transform: scale(0.95);
          }
        }
      }
    }
  }
}

.course-delete-loader-container {
  background-color: #fdff97;
  display: flex;
  justify-content: center;
  padding: 4%;
}
