.address-main-container {
  margin-top: 0.5rem;
  margin-bottom: 0.2rem;
  width: 100%;
  display: flex;
  justify-content: center;
  .pin-container {
    background-color: #252525;
    color: white;
    border-radius: 21px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 316px;
    height: 192px;
    border: solid #eef08d 1.5px;
    img {
      margin-top: 10px;
      margin-bottom: 8px;
      background: transparent;
    }
    .pin-text-container {
      font-family: "Inria Sans", sans-serif;
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      text-align: center;
      p {
        margin-bottom: 0;
        background-color: #252525;
      }
    }
  }
}
