.seller-edit-main-container {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 3%;
    background-color: #212529;
    .seller-edit-title-container {
        display: flex;
        justify-content: center;
        flex-direction: column;
        margin-bottom: 1%;

        .seller-edit-title {
            color: #fdff97;
            text-align: center;
            font-family: "Inria Sans", sans-serif;
            padding-bottom: 1%;
            font-size: 2.05em;
            letter-spacing: 0.03em;
            text-shadow: -2px 3px 3px rgba(255, 255, 255, 0.261);
        }
    }

    .divider {
        color: white;
        margin: 0.7% 0%;
    }
}