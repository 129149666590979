.cart-main-container{
    padding: 20px;
    background-color: #212529;
    color: white;
    height: 90vh;
    .cart-container{
        font-family: 'Inria Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        .cart-discount{
            color: #FFDF8C;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 10px;
            margin-top: 35px;
            .cart-inputText{
                background: transparent;
                border: 1px solid #FFDF8C;
                color: #FFDF8C;
                padding: 5px;
            }
        }
        .cart-myTools{
            display: flex;
            align-items: center;
            gap: 10px;
            margin-top: 30px;
            margin-bottom: 60px;
            p{
                margin-bottom: 0px;
            }
        }
        .cart-total{
            display: flex;
            justify-content: flex-end;
        }
    }
    .cart-button-buy-container{
        display: flex;
        justify-content: center;
        button{
            font-weight: 400;
            font-size: 22px;
            line-height: 26px;
            text-align: center;
            color: #1F2023;
            background: #FDBEF3;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            border-radius: 3px;
            width: 390px;
            height: 54px;
        }
    }
    .cart-item{
        display: flex;
        justify-content: space-between;
        align-items: center; 
        .cart-item-title{
            text-decoration: underline;
            margin-bottom: 0px;
        }
    }
}