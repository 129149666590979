:root {
  --bs-border-color: #bebfc2;
}

.accordion-details-main-container {
  display: flex;
  flex-direction: column;
  gap: 1.55rem;
  .accordion-details-card {
    border: none;
    .accordion-details-item {
      display: flex;
      justify-content: space-between;
      color: white;
      background-color: #212529;
      border-radius: 0px;
      border-top: none;
      border-left: none;
      border-right: none;
      border-bottom: 1px solid;
      .button-accordion {
        background-color: transparent;
        border: none;
      }
    }
    .accordion-detail-card-body {
      background-color: #212529;
      font-weight: 400;
      font-size: 1rem;
      line-height: 18px;
      color: #fff;
      .accordion-detail-card-body-img-container {
        display: flex;
        justify-content: center;
        padding-top: 1%;
        padding-bottom: 2%;
        > img {
          width: 50%;
          @media screen and (max-width: 768px) {
            width: 100%;
          }
        }
      }
      .accordion-detail-card-body-detail {
        margin: 0 auto;
        width: 75%;
        display: flex;
        justify-content: center;
        padding-left: 5%;
        padding-right: 5%;
        margin-top: 1rem;
        font-size: 1rem;
        line-height: 1.5rem;
        @media screen and (max-width: 768px) {
          width: 100%;
        }
      }
    }
  }
}
