.custom-nav-main-container {
  width: 70%;
  .custom-nav-inner-container {
    width: 100%;
    display: flex;
    justify-content: space-around;
  }
}

.nav-item {
  padding: 8px;
  font-size: 16px;
  color: #ffffff;
  :focus {
    color: #eef08d;
  }
  a {
    text-decoration: none;
    color: #ffffff;
    &:hover {
      color: #eef08d;
    }
  }
}

.nav-login-buttons-container {
  display: flex;
  @media screen and (max-width: 768px) {
    justify-content: center;
  }
  .nav-login-button {
    border: none;
    padding: 8px;
    background-color: transparent;
    color: #fff;
    &:hover {
      color: #eef08d;
      transform: scale(1.02);
    }
    &:active {
      transform: scale(0.9);
    }
  }
}
