:root {
  --bs-border-color: #bebfc2;
}

.accordion-main-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  .card-container {
    border: none;
    .accordion-item {
      border: 2px solid #bebfc2;
      display: flex;
      justify-content: space-between;
      color: white;
      background-color: #25292d;
      border-radius: 0px;
      border-top: none;
      border-left: none;
      border-right: none;
      .button-accordion {
        background-color: transparent;
        border: none;
      }
    }
    .CardBody {
      background-color: #212529;
      font-weight: 400;
      font-size: 15px;
      line-height: 18px;
      .grid-container {
        display: grid;
        grid-template-rows: repeat(2, 1fr);
        grid-template-columns: repeat(2, 1fr);
        .grid-item {
          background-color: #212529;
          padding: 10px;
          font-family: "Inria Sans";
          font-style: normal;
          .label {
            color: #edff97;
            font-weight: 700;
            font-size: 17px;
            line-height: 23px;
          }
        }
      }
    }
  }
}
