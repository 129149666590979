.debts-detail-main-container {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 3%;
  background-color: #212529;
  .debts-detail-title-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 1%;
    .debts-detail-title {
      color: #fdff97;
      text-align: center;
      font-family: "Inria Sans", sans-serif;
      padding-bottom: 1%;
      font-size: 2.05em;
      letter-spacing: 0.03em;
      text-shadow: -2px 3px 3px rgba(255, 255, 255, 0.261);
    }
  }
  .debts-detail-grid-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .debts-detail-grid-row {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-around;
      .debts-detail-grid-item {
        margin: 2% 0%;
        .debts-detail-data-container {
          width: 100%;
          background-color: #fdff97;
          padding: 2% 3%;
          border-radius: 15px;
          .debts-detail-data-title {
            background-color: transparent;
            text-align: center;
            font-family: "Inria Sans", sans-serif;
            font-weight: bold;
            padding-bottom: 1%;
            font-size: 1.75em;
            letter-spacing: 0.03em;
            text-shadow: -2px 1px 1px #9748ff8e;
          }
          .debts-detail-data {
            background-color: transparent;
            color: #191919;
            text-align: center;
            font-family: "Inria Sans", sans-serif;
            font-size: 1.25em;
            letter-spacing: 0.03em;
            border-top: 1px solid black;
            padding-top: 1%;
            .careers-detail-schedules-list {
              background-color: #fdff97;
              .careers-detail-available-schedules {
                background-color: transparent;
              }
            }
          }
        }
      }
    }
  }
}
