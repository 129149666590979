.courseTools-container {
  @media screen and (max-width: 768px) {
    background: linear-gradient(
        to bottom,
        rgba(33, 37, 41, 0.8) 0,
        rgba(33, 37, 41, 0.95) 20%,
        rgba(33, 37, 41) 40%
      ),
      url("../../../assets/test-image2-min.jpg");
    background-repeat: no-repeat;
    background-position: center top;
    background-size: contain;
  }
  padding: 20px;
  display: flex;
  justify-content: center;
  background: linear-gradient(
      to bottom,
      rgba(33, 37, 41, 0.8) 0,
      rgba(33, 37, 41, 0.95) 20%,
      rgba(33, 37, 41) 60%
    ),
    url("../../../assets/test-image2-min.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
  .herramientas-container {
    color: #ffffff;
    font-family: "Inria Sans";
    font-style: normal;
    width: 930px;
    display: flex;
    flex-direction: column;
    h1 {
      font-weight: 700;
      font-size: 35px;
      line-height: 42px;
      margin-bottom: 15px;
    }
    h2 {
      font-weight: 400;
      font-size: 25px;
      line-height: 30px;
    }
    p {
      font-size: 16px;
      line-height: 19px;
      color: #eaebc8;
      margin-bottom: 29px;
    }
    .card-maquinas {
      display: flex;
      padding-top: 40px;
      h1 {
        font-family: "Alumni Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 25px;
        line-height: 30px;
        letter-spacing: 0.185em;
        color: #ffffff;
        margin-top: auto;
      }
    }
  }
}
