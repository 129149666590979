.action-buttons-stack {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  margin: 0px;
  .action-button {
    border: none;
    background-color: transparent;
    padding: 2%;
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      transform: scale(1.2);
      > svg {
        color: #fdff97;
      }
    }
    .action-button-icon {
      background-color: transparent;
      width: 80%;
      max-width: 3vw;
      height: 22px;
    }
  }
}
