.sellers-login-main-container {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: #212529;
  .sellers-login-divider {
    color: white;
    margin: 0.7% 0%;
  }
  .sellers-login-form-container {
    .sellers-login-title {
      color: #fdff97;
      font-family: "Inria Sans", sans-serif;
      letter-spacing: 0.225rem;
      font-size: 3rem;
      text-align: center;
      margin-bottom: 0.7%;
    }
  }
}
